/*
  The day at the top of the calender

*/

import React, { Component } from 'react';

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import "./DateHeader.css"
// import NiceDate from '../NiceDate'


class DateHeader extends Component {
  constructor (props) {
    super(props)
    this.props = props
  }

  render () {

    const date = new Date(this.props.startTime + 1000*60*60 + this.props.col*1000*60*60*24)

    const style = {
      gridColumn: this.props.col + 2,
      gridRow: 1,
      // borderBottom: (date.toDateString() === new Date().toDateString()) ? "3px green solid" : "",
      boxShadow: (date.toDateString() === new Date().toDateString()) ? "inset 0px -6px 5px -3px green" : "",
      backgroundColor: "#fafafa",
    }

    return (
      <div className="DateHeader" style={style}>
        <p>
          &nbsp;
          {date.toString().substr(0,3)}
          <span>  </span>
          {/* {date.toISOString().substr(0,10)} */}
          {date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear()}

        </p>
      </div>
    )
  }
}

export default compose(
  firestoreConnect(() => [
    'filters',
  ]),
  connect(state => ({
    startTime: state.filters.dates.startTime,
  }))
)(DateHeader);
