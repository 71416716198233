import React, { Component } from 'react'
import 'firebase/firestore'
import 'firebase/auth'
import './LoginPage.css'
import firebase from 'firebase/app'
import { Redirect } from "react-router-dom";

class LoginPage extends Component{
  constructor(props){
    super(props)
    this.props = props
    this.state =
    {
      redirect: null,
      email: "",
      password: "",
      loginError: "",
    }

  }

  changeEmail = (evt) => {
    this.setState({
      ...this.state,
      email: (evt.target.value)
    })
  }

  changePassword = (evt) => {
    this.setState({
      ...this.state,
      password: (evt.target.value)
    })
  }

  firebaseSignIn = () => {
    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((e)=>{
      console.log("User logged in", e)
    }).catch((err) => {
      this.setState({
        ...this.state,
        loginError: "The email or password you provided is invalid.",
      })
    });
  }

  handleKeyPress = (target) => {
    if(target.charCode===13){
      this.firebaseSignIn()
    }
  }

  registerRedirect = () => {
    this.setState({
      ...this.state,
      redirect: (<Redirect to="/register"/>),
    })
  }

  render() {
    return (
      <div className="LoginPage" align="center">
        <h4>Login</h4>
        <div className="InputContainer">
          Email:
          <input className="LoginInput" type="text" placeholder="Email" onChange={this.changeEmail}></input>
          <br />
          <br />
          Password:
          <input className="LoginInput" type="password" placeholder="Password" onChange={this.changePassword} onKeyPress={this.handleKeyPress}></input>
          
          <br />
          <p style={{ color: 'red' }}>{this.state.loginError}</p>
        
        </div>


        <button className="btn" onClick={this.firebaseSignIn}>Sign In</button>
        <br/>
        <br/>
        Don't have an account?
        <br/>
        <button className="btn" onClick={this.registerRedirect}>Create an Account</button>

        {this.state.redirect}

        {/* 
        {   // Redirect to timesheets after logging in
          (this.props.auth && this.props.auth.isLoaded && this.props.auth.isEmpty)
            ?
          null
            :
          <Redirect to="/timesheets" />
        } */}

      </div>
    )
  }


}
export default LoginPage
