import React, { Component } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import "./Calendar.css"
import Timeblock from './Timeblock'
import DateHeader from './DateHeader'
import HourBlock from './HourBlock'
import AddTimeblock from './AddTimeblock'
import EditTimeblock from './EditTimeblock'
import ToggleEmployees from './ToggleEmployees'
import CalendarGridCell from "./CalendarGridCell"


import { 
  nextDateFilterAction, 
  previousDateFilterAction, 
  enableUserFilterAction,
} from '../../store/actions/filters'


class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popup: null,
      reset: null
    }

  }

  closePopup = () => {
    this.setState({
      ...this.state,
      popup: null,
    })
  }
  addTimeblock = (startTime) => {
    // If function activated through button press default startTime to 0
    if (typeof startTime != "number") startTime = this.props.filters.dates.startTime

    //console.log("adding time block with start_time:", start_time)
    let addPopup = (<AddTimeblock onClose={this.closePopup} startTime={startTime} />)
    this.setState({
      ...this.state,
      popup: addPopup,
    })
  }
  editTimeblock = (targetTimeblock) => {  // Opens the menu to edit an existing timeBlock
    //console.log("Editing timeblock", targetTimeBlock)
    let editPopup = (<EditTimeblock onClose={this.closePopup} timeblock={targetTimeblock} />)
    this.setState({
      ...this.state,
      popup: editPopup,
    })
  }

  toggleEmployees = () => {
    let toggleEmployees = (<ToggleEmployees onClose={this.closePopup} />)
    this.setState({
      ...this.state,
      popup: toggleEmployees,
    })
  }

  previousDate = () => {
    this.props.dispatch(previousDateFilterAction())
  }

  nextDate = () => {
    this.props.dispatch(nextDateFilterAction())
  }

  componentDidUpdate() {
    if (this.props.filters.users[this.props.auth.uid] === undefined) {
      this.props.dispatch(enableUserFilterAction(this.props.auth.uid))
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" })
  }

  returnRef = (el) => {
    if(el){
      el.scrollIntoView({ behavior: "smooth" })
    }
  }

  checkOverlappingTimeblocks = (timeblocks) => {
    // finalTimeblocks is the array of the timeblocks with the correct number of overlaps and x position
    let filteredTimeblocks = []
    // make sure we have timeblocks
    if (timeblocks) {
      // create a new array of the timeblocks, filtered to only contain the timeblocks owned by the user, and sorted by start time.
      filteredTimeblocks = timeblocks.filter(timeblock => { 
        return (timeblock.userId in this.props.filters.users)? this.props.filters.users[timeblock.userId] : false 
      })
      filteredTimeblocks.sort((a, b) => a.startTime - b.startTime)

      // give each timeblock an 'overlaps' and 'x' position
      for (let i in filteredTimeblocks) {
        filteredTimeblocks[i] = this.timeblockWithOverlap(filteredTimeblocks[i])
      }

      for (let i = 0; i < filteredTimeblocks.length -1; i++) {
        // select a timeblock
        let selectedTimeblock = filteredTimeblocks[i]
        // for the selected timeblock, check the number of nested overlaps
        let numOverlaps = this.checkParents(filteredTimeblocks, i, 0, [selectedTimeblock])
        // iterate over the overlapping blocks to set their properties, and then skip that many iterations of the main loop.
        if (numOverlaps > 0) {
          for (let k = 0; k <= numOverlaps; k++) {
            filteredTimeblocks[i + k] = { ...filteredTimeblocks[i + k], overlaps: numOverlaps, x: k }
          }
          i += numOverlaps
        } 
      }
    }
    return filteredTimeblocks
  }

  timeblockWithOverlap = (timeblock) => {
    return {...timeblock, overlaps: 0, x: 0}
  }

  checkParents = (timeblocks, timeblock, num, sublist) => {
    let split = false
    if (timeblock < timeblocks.length -1) {
      for (let block of sublist) {
        if (block.startTime + block.length * 15 * 1000 * 60 > timeblocks[timeblock + 1].startTime) {
          split = true
        }
      }
      if (split) {
        return this.checkParents(timeblocks, timeblock + 1, num + 1, [...sublist, timeblocks[timeblock + 1]])
      } else {
        return num
      }

    }  
    else {
      return num
    }
  }

  callReset = (index) => {
    this.setState({reset: index})
  }

  resetDimensions = (timeblockList, index) => {
    let resetTimeblockList = timeblockList
    resetTimeblockList[index] = {...resetTimeblockList[index], overlaps: 0, x: 0}
    return resetTimeblockList
  }

  render() {
    let timeblockList = this.checkOverlappingTimeblocks(this.props.timeblocks)

    if (this.state.reset !== null) {
      timeblockList[this.state.reset] = {...timeblockList[this.state.reset], overlaps: 0, x: 0}
    }

    let blockLength = 1000 * 60 * 15    // Length in milliseconds of a single timeBlock
    let dayLength = blockLength * 4 * 24    // Length in milliseconds of a day

    // Create calender title in form "<Month> <Year>"
    let calendarTitle = new Date(this.props.filters.dates.startTime).toLocaleDateString(undefined, {month:'long', year:'numeric'});

    const timeblocks = [];
    for (let id in timeblockList) {
      let data = {...timeblockList[id]}
      // Skip creating Timeblock component if it is not a filtered user
      if (!this.props.filters.users[data.userId]) continue

      // Skip creating Timeblock component if is it not inside visible section of date
      if (data.startTime < this.props.filters.dates.startTime) continue
      if (data.startTime >= this.props.filters.dates.startTime + dayLength*7) continue

      data.userName = this.props.users && this.props.users[data.userId].displayName
      timeblocks.push(
        <Timeblock
          id={id}
          key={id}
          data={data}
          editAction={this.editTimeblock}
          resetDimensions={this.callReset}
        />
      )
    }

    // Create date headers above calendar
    const dateHeaders = [];
    for (let i = 0; i < 7; i++) {
      dateHeaders.push(<DateHeader key={i} col={i} />)
    }

    // Create hour block to the left of calendar
    const hourBlocks = [];
    for (let i = 0; i < 24; i++) {
      hourBlocks.push(<HourBlock hour={i} key={i} returnRef={this.returnRef} />)
    }

    // Create a list of selectable grid cells
    const gridCells = [];
    for (let col = 0; col < 7; col++) {
      for (let row = 0; row < 4 * 24; row++) {
        let cellStartTime = this.props.filters.dates.startTime + col*dayLength + row*blockLength
        gridCells.push(
          <CalendarGridCell 
            key={cellStartTime} 
            startTime={cellStartTime} 
            row={row} 
            col={col} 
            addTimeblock={this.addTimeblock} 
          />
        )
      }
    }



    return (
      <div className="Calendar">
        {this.state.popup}

        {/* {this.props.filters.dates.startTime} */}
        {/* <br /> */}
        {/* {""+(new Date(this.props.filters.dates.startTime))} */}

        {/* <div className="UserFilterList">
          {userFilterList}
        </div> */}


        <div className="CalendarTopBar row valign-wrapper">
        
          <div className="CalendarTopBarLeft col s4 left-align">
            <button onClick={this.addTimeblock} className="btn">Add Timeblock</button>
          </div>

          <div className="CalendarTopBarCenter col s4 center-align no-padding">
            <span className="no-padding">{calendarTitle}</span>
          </div>

          <div className="CalendarTopBarRight col s4 right-align">
              {
                (this.props.profile.admin) 
                ? <button className="btn" style={{ marginRight: "30px" }} onClick={this.toggleEmployees}>Employees</button>
                : null
              }
              
              <button onClick={this.previousDate} className="btn">{"<"}</button>
              <button className="btn">Week</button>
              <button onClick={this.nextDate} className="btn">{">"}</button>
            </div>

        </div>

        <div className="CalendarHeader">
          {dateHeaders}
          <div style={{ overflowY: 'scroll', opacity: 0, gridColumn: '9/10' }}></div>
        </div>

        <div className="CalendarGrid">
          {gridCells}
          {hourBlocks}
          {timeblocks}
        </div>

      </div>
    )
  }
}

export default compose(
  firestoreConnect((props) => {
    return [
      {
        collection: 'timeblocks',
        where: [
          // ['userId', '==', "eUqEtvul57NgHyesPVFxYrKSkhK2"],
          ['startTime', '>=', props.filters.dates.startTime],
          ['startTime', '<', props.filters.dates.startTime + props.filters.dates.weekLength],
        ]
      },
      'users',
      'projects',
    ]
  }),
  connect(state => ({
    timeblocks: state.firestore.ordered.timeblocks,
    users: state.firestore.data.users,
    filters: state.filters,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    projects: state.firestore.data.projects,
  }))
)(Calendar)