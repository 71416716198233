import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import NavBar from './components/layout/NavBar'
import Welcome from './components/Welcome'
import LoginPage from './components/LoginPage'
import RegisterPage from './components/RegisterPage'
import Timesheets from './components/timesheets/Timesheets'
import Reports from './components/reports/Reports'
import AuthenticationRedirector from './components/AuthenticationRedirector'
import WelcomeRedirector from './components/WelcomeRedirector'
import ProjectManagement from './components/ProjectManagement/ProjectManagement'

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <NavBar />

          <Switch>
            <Route path="/login" component={WelcomeRedirector} />
            <Route path="/register" component={WelcomeRedirector} />
            <Route path="/" component={AuthenticationRedirector} />
          </Switch>

          <Route exact path="/" component={Welcome} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/timesheets" component={Timesheets} />
          <Route path="/reports" component={Reports} />
          <Route path="/project-management" component={ProjectManagement} />
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
