import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

class AuthenticationRedirector extends Component {
  render() {
    // Only switch if user has not logged in and firebase auth has loaded
    if (this.props.auth.isLoaded && this.props.auth.isEmpty) {    
      // console.log("Not Logged in")
      return (<Redirect to="/login"/>)
    } else {
      // console.log("Yes Logged in")
      return null
    }
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({auth: state.firebase.auth}) )
)(AuthenticationRedirector);