import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import './NewProject.css'
import Popup from '../util/Popup'


class NewProject extends Component {
  constructor (props) {
    super(props)

    this.state = {
      name: '',
      status: 'Open',
      tasks: [],
    }
  }

  updateProjectName = (evt) => {
    this.setState({
      name: evt.target.value
    })
  }

  createProject = () => {
    this.props.firestore.collection('projects').doc(this.state.name).set(this.state)
    this.props.onClose()
  }

  isValidName = () => {
    if (this.state.name === '') return false

    // Don't allow project name same as an existing project
    if (this.state.name in this.props.projects) return false

    return true
  }

  render () {

    return (
      <div className="NewProject">
        <br/><br/>

        <div className="InputNote">
          Project Name:
          <div className="projectNameInputDiv">
            <input type="text" className="projectNameInput" onChange={this.updateProjectName} value={this.state.name}></input>
          </div>
        </div>

        <br/>

        <button 
          className="btn" 
          onClick={this.createProject}
          disabled={!this.isValidName()}
        >
          Create Project
        </button>

        <br/><br/>

      </div>
    )
  }
}

export default Popup(compose(
  firestoreConnect(),
  connect(state => ({
    projects: state.firestore.data.projects,
  }))
)(NewProject), "New Project");
