import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import './EditTimeblock.css'
import Popup from '../util/Popup'
import UpdateTimeblockFields from './UpdateTimeblockFields';


class EditTimeblock extends Component {
  constructor (props) {
    super(props)

    this.state = {
      startTime: this.props.timeblock.startTime,
      length: this.props.timeblock.length,
      projectName: this.props.timeblock.projectName,
      taskName: this.props.timeblock.taskName,
      taskNo: this.props.timeblock.taskNo || '',
      note: this.props.timeblock.note,
      rate: this.props.timeblock.rate,
      userId: this.props.timeblock.userId,
    }

    // console.log("Created editor", this.state)
  }

  /** 
   * Stores new timeblock state recieved from UpdateTimeblockFields 
   */
  updateTimeblockState = (state) => {
    this.setState({
      ...state,
    })
  }

  /**
   * Saves the changes made to the timeblock to the firebase server
   */
  saveChanges = () => {
    // console.log("Saving Changes", this.state, this.props)

    // Set new values in firestore
    this.props.firestore.collection("timeblocks").doc(this.props.timeblock.id).update(this.state)
    this.props.onClose()
  }

  deleteTimeblock = () => {   // Deletes the timeBlock
    this.props.firestore.collection("timeblocks").doc(this.props.timeblock.id).delete()
    this.props.onClose()
  }

  render () {

    return (
      <div className="EditTimeblock">
        <br/><br/>

        <UpdateTimeblockFields 
          updateTimeblockState={this.updateTimeblockState} 
          timeblock={this.state}
        />

        <br/>

        <button 
          className="btn" 
          style={{marginRight:"10px"}} 
          onClick={this.saveChanges}
          disabled={this.state.projectName === ''}
        >
          Save Changes
        </button>
        <button className="btn" onClick={this.deleteTimeblock}>Delete Time Block</button>

        <br/><br/>

      </div>
    )
  }
}

export default Popup(compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
  }))
)(EditTimeblock), "Edit Timeblock");
