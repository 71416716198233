import React, { Component } from 'react'

class NiceTime extends Component {
  render() {

    // Create a nice formatted time string
    let hours = Math.floor(this.props.time / 4)
    let mins = (this.props.time % 4) * 15
    let timeString = ""
    if (hours === 1) {
      timeString += "1 hr "
    } else if (hours >= 2) {
      timeString += hours + " hrs "
    }
    if (mins !== 0 || hours === 0) {
      timeString += mins + " min"
    }
    
    return (<span>{timeString}</span>)
  }
}

export default NiceTime