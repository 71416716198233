import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux' 
import { connect } from 'react-redux'

import './NavBar.css'

class NavBar extends Component {

  // signInWithGoogle = e => {
  //   e.preventDefault()
  //   this.props.firebase.login({
  //     provider: 'google',
  //     type: 'popup',
  //   })
  // }
  
  signOut = e => {
    e.preventDefault()
    this.props.firebase.logout()
  }

  render(){
    return (
      <nav className="NavBar" style={{ backgroundColor:"#9dc6c4"}}>
        <div className="nav-wrapper">
          <a href="/" className="brand-logo left">&nbsp;&nbsp;Timesheets</a>
          <ul className="right">
            <li><Link to="/timesheets">Timesheets</Link></li>
            <li><Link to="/reports">Reports</Link></li>
            {(this.props.profile.admin) && (<li><Link to="/reports/projects">Projects</Link></li>)}
            {(this.props.profile.admin) && (<li><Link to="/project-management">Project Management</Link></li>)}
            {(this.props.auth.isLoaded && this.props.auth.isEmpty)
              ? (
                null
              )
              : (
                <li onClick = {this.signOut}><a href="/">Sign Out</a></li>
              )
            }
          </ul>
        </div>
      </nav>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }) )
)(NavBar);