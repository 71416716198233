import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: "AIzaSyDE4Ga74EMmqus047rOVCNdGpy1OysMwSI",
  authDomain: "ess-timesheets.firebaseapp.com",
  databaseURL: "https://ess-timesheets.firebaseio.com",
  projectId: "ess-timesheets",
  storageBucket: "ess-timesheets.appspot.com",
  messagingSenderId: "1022645376694"
}
firebase.initializeApp(config)
firebase.firestore().settings({})
firebase.auth()

export default firebase