import React, { Component } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import './Reports.css'
import Summary from './Summary'
import IndividualSummary from './IndividualSummary/IndividualSummary'
import ProjectSummary from './ProjectSummary'
import { Route, Switch, Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


class Report extends Component {
  constructor(props) {
    super(props)

    // set the times in the state
    this.state = {
      options: {
        startTime: 0,
        endTime: 0,
        isCustomTime: false,
        showIndividual: true,
        showAll: false,
      },
      page: null,
    }

  }

  componentDidMount() {
    this.resetTimeRange()
  }

  /**
   * Returns the current fornight times (startTime & endTime) in a dictionary
   */
  getCurrentFortnightTimes = () => {

    const fortnightMilliseconds = 14 * 24 * 60 * 60 * 1000

    // 29th May, 2019 is the start of a fortnight, use it to calculate which
    // fortnight we are in
    const referenceStart = new Date(2019, 4, 29)
    // calculate milliseconds modulus from a fortnight in milliseconds
    const referenceMilliseconds = referenceStart.getTime() % fortnightMilliseconds

    // start from the current date, and work back until we have one with the
    // same modulus value
    const currentDate = new Date()
    // remove hours, minutes, seconds, milliseconds for easier calculation
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)

    // figure out how far in milliseconds to go back to reach a referenced
    // fortnight
    let currentMilliseconds = currentDate.getTime() % fortnightMilliseconds
    let offset = 0
    if (currentMilliseconds < referenceMilliseconds) {
      // add a fortnight to ensure the offset is positive
      offset = currentMilliseconds + fortnightMilliseconds - referenceMilliseconds
    } else {
      offset = currentMilliseconds - referenceMilliseconds
    }

    // calculate start of fortnight using the current date and offset
    let startDate = new Date(currentDate.getTime() - offset)
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())

    // calculate the end date as 14 days after the start
    const endTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 14).getTime() - 1

    return {
      startTime: startDate.getTime(),
      endTime: endTime
    }

  }

  nextSummary = () => {
    // add 14 days (one fortnight) to each date
    const startDate = new Date(this.state.options.startTime)
    let startTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 14).getTime()
    const endDate = new Date(this.state.options.endTime)
    let endTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 14 + 1).getTime() - 1

    this.setState({
      ...this.state,
      options: {
        ...this.state.options,
        startTime,
        endTime,
      }
    })
  }

  previousSummary = () => {
    // subtract 14 days (one fortnight) to each date
    const startDate = new Date(this.state.options.startTime)
    let startTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 14).getTime()
    const endDate = new Date(this.state.options.endTime)
    let endTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 14 + 1).getTime() - 1

    this.setState({
      ...this.state,
      options: {
        ...this.state.options,
        startTime: startTime,
        endTime: endTime,
      }
    })
  }

  changeStartDate = (date) => {
    let newStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()

    // Change endTime if startTime was set after endTime
    let newEndTime = this.state.options.endTime
    if (newStartTime >= newEndTime) {
      newEndTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).getTime() - 1
    }

    this.setState({
      options: {
        ...this.state.options,
        startTime: newStartTime,
        endTime: newEndTime,
        isCustomTime: true,
      }
    })
  }

  changeEndDate = (date) => {
    let newEndTime = new Date(date.getFullYear(), date.getMonth(), date.getDate()+1).getTime() - 1

    // Change startTime if endTime was set before startTime
    let newStartTime = this.state.options.startTime
    if (newStartTime >= newEndTime) {
      newStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
    }

    this.setState({
      options: {
        ...this.state.options,
        startTime: newStartTime,
        endTime: newEndTime,
        isCustomTime: true,
      }
    })
  }
  
  resetTimeRange = () => {
    const fortnightTimes = this.getCurrentFortnightTimes()

    this.setState({
      options: {
        ...this.state.options,
        startTime: fortnightTimes.startTime,
        endTime: fortnightTimes.endTime,
        isCustomTime: false,
      }
    })
  }

  render() {
    return (
      <div className="Reports">
        <br/>
        <div className="row valign-wrapper" style={{height:"50px", marginBottom:"20px"}}>

          {(this.state.page === 'projects') && (<div className="switch">
            <label> 
              All Time     
              <br/>
              <input type="checkbox" value={this.state.options.showAll} onClick={(evt) =>{ 
                this.setState({
                  ...this.state,
                  options:{
                    ...this.state.options,
                    showIndividual: !this.state.options.showIndividual,
                    showAll: !this.state.options.showAll
                  }
                })
              }}/>
              <span className="lever"></span>   
            </label>
          </div>)}


          {this.state.options.isCustomTime && !this.state.options.showAll && (
            <button
              className="btn"
              onClick={this.resetTimeRange}
            >
              Reset Date
            </button>
          )}



          <div className="col s8 right-align">
            <span>
              {(this.state.options.showAll) ? 
                'All timeblocks' : 
                (<span className="dateSelectContainer">
                  <button 
                    disabled={this.state.options.showAll || this.state.options.isCustomTime}
                    className="btn"
                    onClick={this.previousSummary}
                  >
                    {"<"}
                  </button>

                  {/* <NiceDate time={this.state.options.startTime} /> */}
                  <DatePicker
                    className="DatePicker"
                    selected={new Date(this.state.options.startTime)}
                    onChange={this.changeStartDate}
                    dateFormat="MMMM d, yyyy"
                  />
                  <span style={{paddingLeft: '0.5em', paddingRight: '0.5em', fontWeight: 'bold'}}>
                    -
                  </span>
                  <DatePicker
                    className="DatePicker"
                    selected={new Date(this.state.options.endTime-1)}
                    onChange={this.changeEndDate}
                    dateFormat="MMMM d, yyyy"
                  />

                  <button
                    disabled={this.state.options.showAll || this.state.options.isCustomTime}
                    className="btn"
                    onClick={this.nextSummary}
                  >
                    {">"}
                  </button>

                </span>)
              }
            </span>
            
            <div style={{display:"inline-block", width:"1px"}} />
          </div>
        </div>


        {/* <br /> {new Date(this.state.options.startTime).toString()} */}
        {/* <br /> {new Date(this.state.options.endTime).toString()} */}


        <Switch>
          <Route exact path="/reports/" component={() => {
            if (this.state.page !== 'reports') this.setState({ page: 'reports' })
            return (
              (this.props.profile && this.props.userId)
              ? <Redirect to={
                (this.props.profile.admin) ? "/reports/summary" : "/reports/" + this.props.userId}
              />
              : <p>Loading</p>)
          }
          } />
          <Route path="/reports/summary" component={() => <Summary options={this.state.options} />}  />
          <Route path="/reports/projects/" component={() => {
            if (this.state.page !== 'projects') this.setState({ page: 'projects' })
            return (<ProjectSummary options={{ 
              ...this.state.options,
              startTime: (this.state.options.showAll) ? 0 : this.state.options.startTime,
              endTime: (this.state.options.showAll) ? Infinity : this.state.options.endTime,
            }} /> )
          }
          } />

          <Route path="/reports/:userId/:projectName" component={(route) => {
            return <IndividualSummary
              options={{
                ...this.state.options,
                userId: route.match.params.userId,
                projectName: route.match.params.projectName,
              }}
            />
          }} />

          <Route path="/reports/:userId" component={(route) => {
            return <IndividualSummary
              options={{
                ...this.state.options,
                userId: route.match.params.userId
              }}
            />
          }} />

        </Switch>
      </div>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect((state) => ({
    userId: state.firebase.auth.uid,
    profile: state.firebase.profile,
  }))
)(Report)
