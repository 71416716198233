import React, { Component } from 'react'
import 'firebase/firestore'
import 'firebase/auth'
import './RegisterPage.css'
import firebase from 'firebase/app'
// import { Redirect } from "react-router-dom";


class RegisterPage extends Component{
  constructor(props){
    super(props)
    this.props = props
    this.state ={
      redirect: null,
      name: "",
      email: "",
      password: "",
      registerError: ""
    }

  }

  changeName = (evt) => {
    this.setState({
      ...this.state,
      name: (evt.target.value)
    })
  }

  changeEmail = (evt) => {
    this.setState({
      ...this.state,
      email: (evt.target.value)
    })
  }

  changePassword = (evt) => {
    this.setState({
      ...this.state,
      password: (evt.target.value)
    })
  }

  firebaseSignUp = () => {
    if (this.state.name.length < 3) {
      this.setState({
        ...this.state,
        registerError: "Name must be at least 3 characters.",
      })
      return;
    }

    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((evt)=>{

      firebase.firestore().collection("users").doc(evt.user.uid).set({
        id: evt.user.uid,
        displayName: this.state.name,
      }).then(()=>{

      })

    }).catch((err) => {
      console.log(err)
      this.setState({
        ...this.state,
        registerError: err.message,
      })
    });

  }

  render() {
    return (
      <div className="RegisterPage" align="center">
        <h4>Register</h4>
        <div className="InputContainer">
          Name: <input className="RegisterInput" type="text" placeholder="Full name" onChange={this.changeName}></input>
          <br/><br/>
          Email: <input className="RegisterInput" type="text" placeholder="Valid email address" onChange={this.changeEmail}></input>
          <br/><br/>
          Password <input className="RegisterInput" type="password" placeholder="Real Envisage will never request your password." onChange={this.changePassword}></input>
          <br/>
          <br/>
          <p style={{ color: 'red' }}>{this.state.registerError}</p>
          <button className="btn" onClick = {this.firebaseSignUp}>Sign Up</button>
        </div>

        {this.state.redirect}
      </div>

    )
  }

}
export default RegisterPage;
