import React, { Component } from 'react'
import NiceTime from '../../util/NiceTime'
import NiceDate from '../../util/NiceDate'
import './TaskBreakdown.css'

class TaskBreakdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }
  
  render() {

    // add all the timeblock components in this task to array
    let timeblockComponents = []

    let taskTimeblocks = this.props.task.timeblocks
    for (let timeblockId in taskTimeblocks) {
      const timeblock = taskTimeblocks[timeblockId]
      timeblockComponents.push(
        <div className="timeblockSummary row" key={timeblockId}>
        
          <div className="col s8 left-align">
            <NiceDate time={timeblock.startTime} />
            
            <br />
            <div className="note">
              {timeblock.taskNo}
              {timeblock.taskNo && timeblock.note ? ' - ' : null}
              {timeblock.note}
            </div>
          </div>

          <div className="col s4 right-align">
            <NiceTime time={timeblock.length} />
            <br />
            <span className="IndividualSummary aside">
              {timeblock.rate}x rate
            </span>
          </div>

        </div>
      )
    }

    return (
      <div className="TaskBreakdown row">
        <div className="taskTitle row">
          <div className="expanded-icon" onClick={this.toggleExpanded}>
            {this.state.expanded ? '-' : '+'}
          </div>
          <div className="col s6 left-align">
            {this.props.task.taskName}
          </div>
          <div className="col s6 right-align">
            <NiceTime time={(this.props.task.total)} />
          </div>
        </div>
        <div style={{'display': this.state.expanded ? '' : 'none'}}>
          {timeblockComponents}
        </div>
      </div>
    )
  }
}

export default TaskBreakdown
