import React, { Component } from 'react';
import "./CalendarGridCell.css"


class CalendarGridCell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      style: {
        gridColumn: this.props.col+2,
        gridRow: this.props.row+1,
        borderBottomColor: "rgba(0, 0, 0, " +((this.props.row%4===3)? 0.2:((this.props.row%2===1)?0.1:0.0)) + ")",

      },
      interval: undefined,
      time: undefined,
    }

  }

  addTimeblockAtGridLocation = () => {
    this.props.addTimeblock(this.props.startTime)
  }

  componentDidMount() {
    this.setState({
      interval: (setInterval(() => this.setState({ time: Date.now() }), 10000))
    })
  }
  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    let dayStartTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()
    let currentTime = new Date().getTime() - dayStartTime
    // console.log(currentTime / 1000 / 60 / 60 / 24)
    let currentGridCell = Math.floor(96*(currentTime / 1000 / 60 / 60 / 24))
    let currentHour = ((currentTime / 1000 / 60 / 60 % 1) % 0.25) * 4

    return (
      <div className="CalendarGridCell" style={this.state.style} onClick={this.addTimeblockAtGridLocation} >
      
        {(new Date(this.props.startTime).getDate() === new Date().getDate() && this.props.row === currentGridCell) && (<div className="calendarGridCellMarker" style={{
          // border: '1px solid',
          boxShadow: '0px 0px 1px 1px green',
          position: 'relative',
          width: '100%',
          zIndex: "1",
          top: `calc(${currentHour}*100%)`,
          left: `0`,
          pointerEvents: 'none',
        }}></div>)}
      
      </div>
    )

  }
}

export default CalendarGridCell;
