import React, { Component } from 'react';
import "./Popup.css"
import crossImage from '../../images/cross.png'

/*
  The Popup HOC adds popup functionality to components
  Whether or not the popup is visible is NOT stored in the component,
  and must be determined elsewhere.
  e.g. {editingTimeBlock ? <PopupHOCForEditing /> : null}
  
  Popups must be passed a functional prop called onClose which is executed 
  when the popup is closed (either through the cross or by clicking outside of the popup).
  This function should cause the popup to no longer render or else the popup will not close.
  e.g. {editingTimeBlock = true}

  Popups can also be closed from within the WrappedComponent?
  For example what happens when the popups function (like editing a timeblock) is executed and the popup needs to close?

*/

const Popup = (WrappedComponent, popupTitle) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        title: popupTitle
      }
    }

    closePopup = () => {
      this.props.onClose()
    }

    render() {
      return (
        <div className="Popup">
        	<div className="PopupElement">
            <div className="PopupTopBar valign-wrapper row">

              <div className="PopupTitle col s12">
                {this.state.title}
              </div>

              <img className="PopupCloseImage" onClick={this.closePopup} src={crossImage} alt="X" />


            </div>

            <div className="PopupContent">
              <WrappedComponent {...this.props} />
            </div>

        	</div>
        </div>
      )
    }
  }
}

export default Popup;
