import React, { Component } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'

import './IndividualSummary.css'
import './TaskBreakdown'
import NiceTime from '../../util/NiceTime'
import NiceDate from '../../util/NiceDate'
import TaskBreakdown from './TaskBreakdown'
import IndividualProjectSummary from './IndividualProjectSummary'

class IndividualSummary extends Component {
  /**
   * Generate and return a JSON formatted summary of the user's projects and tasks.
   */
  getSummary() {
    let timeblocks = this.props.timeblocks
    let summary = {
      projects: {},
      total: 0,
      payable_total: 0,
    }

    // Populate 'projects' dictionary with tasks and timeblocks of those tasks.
    for (let id in timeblocks){
      let timeblock = timeblocks[id]
      let task = timeblock.taskName
      let project = timeblock.projectName

      // Don't try and work with timeblocks that have been deleted, or are owned by somebody else
      if (
        !timeblock
        || timeblock.userId !== this.props.options.userId
        || timeblock.startTime < this.props.options.startTime
        || timeblock.startTime >= this.props.options.endTime
      ) continue

      // Add timeblock length to cumulative totals
      summary.total += timeblock.length
      summary.payable_total += timeblock.length * timeblock.rate

      // If the project name isn't the dictionary of current projects, add it.
      if (!(project in summary.projects)) {
        summary.projects[project] = {
          total: 0,
          payable_total: 0,
          tasks: {},
        }
      }

      // Add to total for timeblocks project
      summary.projects[project].total += timeblock.length
      summary.projects[project].payable_total += timeblock.length * timeblock.rate

      // If no task name specified, use "(unassigned)"
      if (!task) task = '(unassigned)'

      // If the task name doesn't exist, add it.
      if (!summary.projects[project].tasks[task]){
        summary.projects[project].tasks[task]  = {
          total: 0,
          payable_total: 0,
          timeblocks: {}
        }
      }

      // Add to total for timeblocks task
      summary.projects[project].tasks[task].total += timeblock.length
      summary.projects[project].tasks[task].payable_total += timeblock.length * timeblock.rate

      // Add the timeblock to the relevant task name and project.
      summary.projects[project].tasks[task].timeblocks[id] = timeblock
    }

    summary = this.getSortedSummary(summary)
    return summary
  }

  getSortedSummary(summary) {
    /** Converts summary projects/tasks dictionaries into sorted lists */
    
    // Sort projects and replace projects dictionary with projects array
    let projectsArray = []
    for (let projectName in summary.projects) {
      let project = summary.projects[projectName]
      project.projectName = projectName
      projectsArray.push(project)

      // Sort tasks and replace tasks dictionary with tasks array
      let tasksArray = []
      for (let taskName in project.tasks) {
        let task = project.tasks[taskName]
        task.taskName = taskName
        tasksArray.push(task)
      }
      tasksArray.sort((a,b)=>(b.total - a.total))
      project.tasks = tasksArray

    }
    projectsArray.sort((a,b)=>(b.total - a.total))
    summary.projects = projectsArray

    return summary
  }

  getProjectComponent(project, user) {

    // Generate list of task components
    let taskComponents = []
    for (let i in project.tasks){
      let task = project.tasks[i]
      let taskComponent = <TaskBreakdown task={task} key={task.taskName} />
      taskComponents.push(taskComponent)
    }

    return (
      <div className="project row" key={project.projectName}>
        <div className="projectTitle row valign-wrapper">
          <div className="col s6 left-align">
            <Link 
              className="individualProjectSummaryLink"
              to={`/reports/${user.id}/${project.projectName}`}
            >
              {project.projectName}
            </Link>
          </div>
          <div className="col s6 right-align">
            <NiceTime time={project.total} />
          </div>
        </div>
        {taskComponents}
      </div>
    )

  }

  render() {
    if (!this.props.timeblocks || !this.props.users) return <></>
    
    let user = this.props.users[this.props.options.userId]
    if (!user) return <p>Invalid User</p>

    const userDisplayName = user.displayName
  
    // Nested JSON of all projects -> tasks -> timeblocks
    let summary = this.getSummary()
    // console.log(summary)

    // Return individual project summary
    if (this.props.options.projectName) {
      return <IndividualProjectSummary
        options={{
          ...this.props.options,
          project: summary.projects.filter(
            project => project.projectName === decodeURI(this.props.options.projectName)
          )[0],
          user: user
        }}
      />
    }

    // Generate project components from summary
    let projectComponents = []
    for (let i in summary.projects){
      let project = summary.projects[i]
      let projectComponent = this.getProjectComponent(project, user)
      projectComponents.push(projectComponent)
    }

    return (
      <div className="IndividualSummary center-align card">

        <div className="header row left-align">
          <div className="title">
            {userDisplayName}
          </div>
          <div className="desc">
            <NiceDate time={this.props.options.startTime} />
            {" - "}
            <NiceDate time={this.props.options.endTime-1} />
          </div>
        </div>

        <div className="content">
          <div className="overallBar row valign-wrapper">
            <div className="col s6 left-align">
              Projects
            </div>
            <div className="col s6 right-align">
              HOURS
              <br />
              <NiceTime time={summary.total} />
              <br />
              <span className="aside">
                {summary.payable_total*0.25} payable hours
              </span>
            </div>
          </div>

          {(projectComponents.length > 0) ? 
            projectComponents : 
            (<div>
              Nothing to display.
              <br /><br />
            </div>)
          }
        </div>

      </div>
    )

  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: 'timeblocks',
      where: [
        ['startTime', '>=', props.options.startTime],
        ['startTime', '<', props.options.endTime],
        ['userId', '==', props.options.userId],
      ]
    },
    'users',
  ]),
  connect((state) => ({
    timeblocks: state.firestore.data.timeblocks,
    users: state.firestore.data.users,
  }))
)(IndividualSummary)
