import React from 'react';
import Select from 'react-select';

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import './IndividualProject.css'

const IndividualProject = (props) => {
  if (!props.project) return

  const changeProjectStatus = (state) => {
    props.firestore.collection("projects").doc(props.project.name).update({
      'tasks-map': {
        ...props.project['tasks-map'],
        [state.taskName]: state.value
      }
    })
  }

  const tasks = props.project['tasks-map'] || {}
  const taskRows = []

  if (props.project.tasks) {
    for (let taskName of props.project.tasks) {
      const status = tasks[taskName] ? 'Open' : 'Closed'

      const statusOptions = {
        'Open': {label: 'Open', value: true, color: 'green', taskName: taskName},
        'Closed': {label: 'Closed', value: false, color: 'red', taskName: taskName},
      }

      taskRows.push(
        <tr key={taskName}>
          <td style={{
            color: (statusOptions[status]) ? statusOptions[status].color : 'black'
          }}>{taskName}</td>
          <td className="right-align" style={{width: '10em'}}>
            <Select
              className="statusSelect smallSelect"
              isClearable={false}
              isSearchable={true}
              options={Object.values(statusOptions)}
              onChange={changeProjectStatus}
              defaultValue={statusOptions[status]}
            />
          </td>
        </tr>
      )
    }
  }
  
  return (
    <div className="IndividualProject">
      <div className="projectName">Project: {props.project.name}</div>

      <table className="taskTable">
        <tbody>
          <tr>
            <th>Task Name</th>
            <th style={{textAlign: 'right'}}>Status</th>
          </tr>
          {taskRows}
        </tbody>
      </table>

    </div>
  )
}

export default compose(
  firestoreConnect(props => [
    {
      collection: 'projects'
    }
  ]),
  connect((state) => ({
    projects: state.firestore.data.projects,
  }))
)(IndividualProject)
