import React, { Component } from 'react'

import Calendar from './Calendar'
import './Timesheets.css'

import { compose } from 'redux'
import { connect } from 'react-redux'

class Timesheets extends Component {
  render () {
    return (
      <div className="Timesheets ">
        <div className="CalendarContainer">
          <Calendar filters={this.props.filters}/>
        </div>
      </div>
    )
  }
}


export default compose(
  connect(state => ({
    filters: state.filters,
  }))
)(Timesheets)
