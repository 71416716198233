/*
  The blocks showing the hour on the left on the calender

*/

import React, { Component } from 'react';


class HourBlock extends Component {
  constructor (props) {
    super(props)
    this.props = props
    this.state = {...props}

    this.style = {
    	gridColumn: 1,
    	gridRowStart: 4*this.state.hour + 1,
      gridRowEnd: 4*this.state.hour + 5,
      backgroundColor: "#e0e0e0", //"#f8f8f8",//"#aba8a8",
      borderBottom: "1px solid #aaa",
      borderRight: "1px solid grey",
    }


  }


 

  render () {
    // this.scrollToBottom();
    if (this.state.hour === 8){
      return(
        <div className="HourBlock" 
          style={{ 
            float:"left",
            clear: "both",
            gridColumn: 1,
            gridRowStart: 4*this.state.hour + 1,
            gridRowEnd: 4*this.state.hour + 5,
            backgroundColor: "#e0e0e0", //"#f8f8f8",//"#aba8a8",
            borderBottom: "1px solid #aaa",
            borderRight: "1px solid grey",
          }}
          ref={this.props.returnRef} 
        > 
          <p style={{"marginTop":"0px"}}>
          {(this.state.hour%12===0)?12:this.state.hour%12}:00 {(this.state.hour<12?"am":"pm")}
          </p>
        </div>
      )
      
    }
    else{
      return (
        <div className="HourBlock" style={this.style}>
          <p style={{"marginTop":"0px"}}>
          {(this.state.hour%12===0)?12:this.state.hour%12}:00 {(this.state.hour<12?"am":"pm")}
          </p>
        </div>
      )
     }
    }
}

export default HourBlock;
