import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

class WelcomeRedirector extends Component {
  render() {
    // Only switch if user has not logged in and firebase auth has loaded
    if (this.props.auth.isLoaded && this.props.auth.isEmpty) {
      return null
    } else {
      return (<Redirect to="/"/>)
    }
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({auth: state.firebase.auth}) )
)(WelcomeRedirector);