import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

class Welcome extends Component {
  constructor(props){
    super(props)
    this.state = {
      display: null,
    }
  }

  render() {
    return(
      <div>
        <h3>Welcome.</h3>
        <span>{this.state.display}</span>
        <br/><br/>
        {
          this.props.profile.displayName
          ? <p>Currently logged in as <b>{this.props.profile.displayName}</b></p>
          : <p>Loading user...</p>
        }
      </div>
    )
  }

}

export default compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }) )
)(Welcome);
