
export const nextDateFilterAction = () => {
  return {
    type: 'NEXT_DATE_FILTER',
  }
}

export const previousDateFilterAction = () => {
  return {
    type: 'PREVIOUS_DATE_FILTER',
  }
}

export const enableUserFilterAction = (userId) => {
  return {
    type: 'ENABLE_USER_FILTER',
    userId: userId,
  }
}

export const disableUserFilterAction = (userId) => {
  return {
    type: 'DISABLE_USER_FILTER',
    userId: userId,
  }
}
