import React, { Component } from 'react'

import './IndividualProjectSummary.css'
import NiceTime from '../../util/NiceTime'
import NiceDate from '../../util/NiceDate'
import './TaskBreakdown'
import TaskBreakdown from './TaskBreakdown'

class IndividualProjectSummary extends Component {

  render() {
    if (!this.props.options.project) return <p>Invalid Project Name</p>

    // Generate project components from summary
    let taskComponents = []
    for (let task of this.props.options.project.tasks){
      let taskComponent = <TaskBreakdown task={task} key={task.taskName}/>
      taskComponents.push(taskComponent)
    }

    return (
      <div className="IndividualProjectSummary center-align card">

        <div className="header row left-align">
          <div className="title">
          {/* {this.props.options.user.displayName} */}
            <div className="col s6 left-align">
              {this.props.options.user.displayName}
            </div>
            <div className="col s6 right-align">
              {this.props.options.project.projectName}
            </div>
          </div>
          <div className="desc">
            <NiceDate time={this.props.options.startTime} />
            {" - "}
            <NiceDate time={this.props.options.endTime-1} />
          </div>
        </div>

        <div className="content">
          <div className="overallBar row valign-wrapper">
            <div className="col s6 left-align">
              Tasks
            </div>
            <div className="col s6 right-align">
              HOURS
              <br />
              <NiceTime time={this.props.options.project.total} />
              <br />
              <span className="aside">
                {this.props.options.project.payable_total*0.25} payable hours
              </span>
            </div>
          </div>

          {(taskComponents.length > 0)
           ? taskComponents
           : <div>Nothing to display.<br/><br/></div>}
        </div>

      </div>
    )

  }
}

export default IndividualProjectSummary
