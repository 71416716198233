import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './UpdateTimeblockFields.css'

const timeInterval = 1000*60*15;

class UpdateTimeblockFields extends Component {
  constructor(props) {
    super(props)

    this.state = {
      renderLength: this.props.timeblock.length
    }
  }

  changeStartDate = (date) => {
    let newDate = new Date(this.props.timeblock.startTime)
    newDate.setFullYear(date.getFullYear())
    newDate.setMonth(date.getMonth())
    newDate.setDate(date.getDate())
    console.log(newDate)
    this.props.updateTimeblockState({
      startTime: newDate.getTime(),
    })
  }

  changeStartTime = (startTime) => {
    this.props.updateTimeblockState({
      startTime: startTime.getTime()
    });

    const prevEndTime = this.props.timeblock.startTime + this.props.timeblock.length*timeInterval;
    if (startTime.getTime() >= prevEndTime) {
      this.updateTimeblockLength(1);
    } else {
      this.updateTimeblockLength((prevEndTime - startTime.getTime())/timeInterval);
    }
  }

  changeEndTime = (endTime) => {
    if (endTime.getTime() <= this.props.timeblock.startTime) {
      this.props.updateTimeblockState({
        startTime: endTime.getTime() - timeInterval
      });
      this.updateTimeblockLength(1);
    } else {
      this.updateTimeblockLength((endTime.getTime() - this.props.timeblock.startTime)/timeInterval)
    }
  }

  /**
   * Attempt to update timeblock length
   * This will always update render length,
   * but will skip the actual length if length is invalid.
   */
  updateTimeblockLength = (length) => {
    this.setState({
      renderLength: length
    })

    this.props.updateTimeblockState({
      length: (length === '') ? this.props.timeblock.length : parseInt(length),
    })
  }

  changeLength = (evt) => {
    // Don't let end of timeblock occur on next day
    const startTime = new Date(this.props.timeblock.startTime);
    const dayEnd = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 23, 45);
    if (this.props.timeblock.startTime + parseInt(evt.target.value)*timeInterval > dayEnd) return;

    this.updateTimeblockLength(evt.target.value)
  }

  changeNote = (evt) => {
    this.props.updateTimeblockState({
      note: evt.target.value,
    })
  }

  changeRate = (evt) => {
    this.props.updateTimeblockState({
      rate: parseFloat(evt.target.value),
    })
  }

  changeProjectName = (evt) => {
    // If changing project name, reset task also
    this.props.updateTimeblockState({
      projectName: evt.label,
      taskName: '',
    })
  }

  changeTaskName = (evt) => {
    this.props.updateTimeblockState({
      taskName: evt.label,
    })
  }

  changeTaskNo = (evt) => {
    this.props.updateTimeblockState({
      taskNo: evt.target.value.replace(/[^-a-zA-Z0-9]+/g, ''),
    })
  }

  /** Returns the list of possible projects for the dropdown */
  getProjects = () => {
    // Create a list of projects for the dropdown
    let projectOptions = [];

    // Add the remaining projects 
    for (let i in this.props.projects) {
      let project = this.props.projects[i]
      if (project.name === this.props.timeblock.projectName) continue

      // Don't add "Closed" projects
      if (project.status !== 'Open') continue

      projectOptions.push({
        label: project.name,
        value: project.name,
      })


    }

    projectOptions.sort((a,b)=>{
      return 2*(a.label.toUpperCase() > b.label.toUpperCase()) - 1
    })

    // Add the currently selected project to the top of the dropdown (if it exists)
    if (this.props.timeblock.projectName !== '') {
      projectOptions.unshift({
        label: this.props.timeblock.projectName,
        value: this.props.timeblock.projectName,
      })
    }


    return projectOptions
  }

  /** Returns the task list based on the currently selected project */
  getTasks = () => {
    // If no project selected, return no tasks
    if (this.props.timeblock.projectName === '') {
      return []
    }

    // Create a list of tasks for the dropdown
    let taskOptions = [];
    
    let tasksMap = this.props.projects[this.props.timeblock.projectName]['tasks-map'] || {};
    let tasks = Object.keys(tasksMap).filter(task => tasksMap[task])

    // Add the remaining tasks 
    for (let i in tasks) {
      let task = tasks[i]
      if (task === this.props.timeblock.taskName) continue
      taskOptions.push({
        value: task,
        label: task,
      })
    }

    taskOptions.sort((a,b)=>{
      return 2*(a.label.toUpperCase() > b.label.toUpperCase()) - 1
    })

    // Add the currently selected task to the top of the dropdown (if it exists)
    if (this.props.timeblock.taskName !== '') {
      taskOptions.unshift({
        value: this.props.timeblock.taskName,
        label: this.props.timeblock.taskName,
      })
    }


    return taskOptions
  }

  /** Creates a new task and adds it to the firestore project task list */
  createNewTask = (taskName) => {
    console.log("Creating new task", taskName)
    this.props.firestore.collection("projects").doc(this.props.timeblock.projectName).update({
      ...this.props.projects[this.props.timeblock.projectName],
      tasks: [
        ...this.props.projects[this.props.timeblock.projectName].tasks,
        taskName,
      ],
      'tasks-map': {
        ...this.props.projects[this.props.timeblock.projectName]['tasks-map'],
        [taskName]: true,
      }
    })

    // 'After' creating new task, switch to it.
    this.props.updateTimeblockState({
      // ...this.props.timeblock,
      taskName: taskName,
    })
  }

  render () {
    let date = new Date(this.props.timeblock.startTime)
    let dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    let dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 45)

    let projectOptions = this.getProjects()
    let taskOptions = this.getTasks()

    return (
      <div className="UpdateTimeblockFields">

        <div className="InputTop row">

          <div className="InputProjectName col s5">
            Project Name
            <Select
              className="smallSelect"
              placeholder={"Select Project..."}
              isClearable={false}
              isSearchable={true}
              options={projectOptions}
              onChange={this.changeProjectName}
              defaultValue={(this.props.timeblock.projectName === '') ? false : projectOptions[0]}
            /> 
          </div>

          <div className="InputTaskName col s5">
            Task Name
            <CreatableSelect
              className="smallSelect"
              placeholder={"Select Task..."}
              isDisabled={this.props.timeblock.projectName === ''}  // Disable if no project selected
              isClearable={false}
              isSearchable
              options={taskOptions}
              value={(this.props.timeblock.taskName === '') ? false : taskOptions[0]}
              onChange={this.changeTaskName}
              onCreateOption={this.createNewTask}
            />
          </div>

          <div className="inputTaskNo col s2">
            Task No.
            <input
              type="text"
              value={this.props.timeblock.taskNo || ''}
              onChange={this.changeTaskNo}
              spellCheck="false"
              disabled={this.props.timeblock.projectName === ''}
            ></input>
          </div>

        </div>

        <br/>

        <div className="InputTimes">
          <div className="row">
            <div className="col s3">
              Date
              <br/>
              <DatePicker
                selected={new Date(this.props.timeblock.startTime)}
                onChange={this.changeStartDate}
                dateFormat="MMMM d, yyyy"
              />
            </div>
            <div className="col s3">
              Start Time
              <br/>
              <DatePicker
                selected={new Date(this.props.timeblock.startTime)}
                onChange={this.changeStartTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="h:mm aa"
                timeCaption="Time"
              />
            </div>
            <div className="col s3">
              End Time
              <br/>
              <DatePicker
                selected={
                  new Date(
                    this.props.timeblock.startTime +
                    this.props.timeblock.length*timeInterval
                  )
                }
                onChange={this.changeEndTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                minTime={new Date(dayStart.getTime() + 1000*60*15)}
                maxTime={dayEnd}
                dateFormat="h:mm aa"
                timeCaption="Time"
              />
            </div>
            <div className="col s3">
              Length
              <br/>
              <input
                type="number"
                min={1}
                value={this.state.renderLength}
                onChange={this.changeLength}
              ></input>
            </div>
          </div>
        </div>

        <div className="inputNote">
          Note <br/>
          <textarea
            type="text-area"
            value={this.props.timeblock.note}
            onChange={this.changeNote}
          ></textarea>
        </div>

        <div className="ToggleOvertime">
          <div className="inputRate row">
            Rate
          </div>

          <div className="row">
            <label className="col s2">
              <input 
                name="rate" 
                type="radio" 
                onChange={this.changeRate} 
                value={1} 
                checked={this.props.timeblock.rate === 1} 
              />
              <span>Standard</span>
            </label>

            <label className="col s2">
              <input 
                name="rate" 
                type="radio" 
                onChange={this.changeRate} 
                value={1.5} 
                checked={this.props.timeblock.rate === 1.5} 
              />
              <span>1.5x</span>
            </label>

            <label className="col s2">
              <input 
                name="rate" 
                type="radio" 
                onChange={this.changeRate} 
                value={2} 
                checked={this.props.timeblock.rate === 2}    
              />
              <span>2x</span>
            </label>
          </div>

        </div>
      
      </div>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    projects: state.firestore.data.projects,
  }))
)(UpdateTimeblockFields)
