
let dayLength = 1000*60*60*24
let weekLength = dayLength*7;

// Calculate start of week (monday 12am)
let currentDate = new Date()   // Current time
let dayStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())  // Start of day
let weekStart = dayStart.getTime() - ((dayStart.getDay() + 6)%7) * dayLength    // Start of week (+6 makes Monday start of week)


const defaultFilters = {
  users: {},
  dates: {
    mode: "week",
    weekLength: weekLength,
    startTime: weekStart,   // Start weeks on the first monday
  },
}

// const modeLengths = {
//   "week":  7,
//   "month": 30,
// }

const filtersReducer = (state = defaultFilters, action) => {
  switch(action.type) {
    case 'NEXT_DATE_FILTER':
      return {
        ...state,
        dates: {
          ...state.dates,
          startTime: state.dates.startTime + weekLength,
        }
      }
    case 'PREVIOUS_DATE_FILTER':
      return {
        ...state,
        dates: {
          ...state.dates,
          startTime: (state.dates.startTime < weekLength) ? state.dates.startTime : state.dates.startTime - weekLength,
        }
      }
    case 'ENABLE_USER_FILTER':
      return {
        ...state,
        users: {
          ...state.users,
          [action.userId]: true,
        }
      }
    case 'DISABLE_USER_FILTER':
      return {
        ...state,
        users: {
          ...state.users,
          [action.userId]: false,
        }
      }
    //case 'RESET_FILTERS':
    //  return defaultFilters
    default:
      return state
  }
}

export default filtersReducer