import React, { Component } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import './ProjectSummary.css'
import NiceTime from '../util/NiceTime'
import NiceDate from '../util/NiceDate'


class ProjectSummary extends Component {

  /**
   * Generate and return a JSON formatted summary of the user's projects and tasks.
   */
  getSummary() {
    let timeblocks = this.props.timeblocks
    let summary = {
      projects: {},
      total: 0,
      payable_total: 0,
    }
    // console.log(this.props.options)

    // Populate 'projects' dictionary with tasks and timeblocks of those tasks.
    for (let id in timeblocks) {
      let timeblock = timeblocks[id]
      let task = timeblock.taskName
      let project = timeblock.projectName

      // Don't try and work with timeblocks that have been deleted, or are owned by somebody else
      if (
        !timeblock
        || timeblock.startTime < this.props.options.startTime
        || timeblock.startTime >= this.props.options.endTime
      ) continue

      // Add timeblock length to cumulative totals
      summary.total += timeblock.length
      summary.payable_total += timeblock.length * timeblock.rate

      // If the project name isn't the dictionary of current projects, add it.
      if (!(project in summary.projects)) {
        summary.projects[project] = {
          total: 0,
          tasks: {},
        }
      }

      // Add to total for timeblocks project
      summary.projects[project].total += timeblock.length

      // If no task name specified, use "(unassigned)"
      if (!task) task = '(unassigned)'

      // If the task name doesn't exist, add it.
      if (!summary.projects[project].tasks[task]) {
        summary.projects[project].tasks[task] = {
          total: 0,
          timeblocks: {}
        }
      }

      // Add to total for timeblocks task
      summary.projects[project].tasks[task].total += timeblock.length

      // Add the timeblock to the relevant task name and project.
      summary.projects[project].tasks[task].timeblocks[id] = timeblock
    }

    summary = this.getSortedSummary(summary)
    return summary
  }

  getSortedSummary(summary) {
    /** Converts summary projects/tasks dictionaries into sorted lists */

    // Sort projects and replace projects dictionary with projects array
    let projectsArray = []
    for (let projectName in summary.projects) {
      let project = summary.projects[projectName]
      project.projectName = projectName
      projectsArray.push(project)

      // Sort tasks and replace tasks dictionary with tasks array
      let tasksArray = []
      for (let taskName in project.tasks) {
        let task = project.tasks[taskName]
        task.taskName = taskName
        tasksArray.push(task)
      }
      tasksArray.sort((a, b) => (b.total - a.total))
      project.tasks = tasksArray

    }
    projectsArray.sort((a, b) => (b.total - a.total))
    summary.projects = projectsArray

    return summary
  }

  getProjectComponent(project) {

    // Generate list of task components
    let taskComponents = []
    for (let i in project.tasks) {
      let task = project.tasks[i]
      let taskComponent = this.getTaskComponent(task)
      taskComponents.push(taskComponent)
    }

    return (
      <div className="Project row" key={project.projectName}>
        <div className="ProjectTitle row valign-wrapper">
          <div className="col s6 left-align">
            {project.projectName}
          </div>
          <div className="col s6 right-align">
            <NiceTime time={project.total} />
          </div>
        </div>
        {taskComponents}
      </div>
    )

  }

  getTaskComponent(task) {
    // add all the timeblock components in this task to array
    let timeblockComponents = []

    let taskTimeblocks = task.timeblocks
    for (let timeblockId in taskTimeblocks) {

      // // get the id of the owner of the timeblock, then lockup their name
      // let taskUserId = taskTimeblocks[timeblockId].userId
      // if (this.props.users) {
      //   let taskUserName = (this.props.users[taskUserId].displayName)
      // }

      if (this.props.options.showIndividual) {timeblockComponents.push(
        <div className="TimeblockSummary row" key={timeblockId}>
          <div className="col s8 left-align">
            <NiceDate time={taskTimeblocks[timeblockId].startTime} />
            {' - '}
            <i>
             {(this.props.users) ? (this.props.users[taskTimeblocks[timeblockId].userId].displayName) : 'Loading'}
            </i>
            <br />
            <i>{taskTimeblocks[timeblockId].note}</i>
          </div>

          <div className="col s4 right-align">
            <NiceTime time={taskTimeblocks[timeblockId].length} />
            <br />
            <span className="IndividualSummary aside">
              {taskTimeblocks[timeblockId].rate}x rate
            </span>
          </div>

        </div>
      )}
    }

    return (
      <div className="Task row" key={task.taskName}>
        <div className="TaskTitle row">
          <div className="col s6 left-align">
            {task.taskName}
          </div>
          <div className="col s6 right-align">
            <NiceTime time={(task.total)} />
          </div>
        </div>
        {timeblockComponents}
      </div>
    )

  }
  render() {
    //console.log(this.props)
    //console.log(this.props.options.endTime)

    // Get user display name only when users list has loaded

    // Nested JSON of all projects -> tasks -> timeblocks
    let summary = this.getSummary()
    // console.log(summary)

    // Generate project components from summary
    let projectComponents = []
    for (let i in summary.projects) {
      let project = summary.projects[i]
      let projectComponent = this.getProjectComponent(project)
      projectComponents.push(projectComponent)
    }

    return (
      <div className="ProjectSummary center-align card">

        <div className="Header row left-align">
          <div className="Title">
            <div className="SummaryTitle">
              Project Summary
            </div>
          </div>
          {!(this.props.options.showAll) && (<div className="Desc">
            <NiceDate time={this.props.options.startTime} />
            {" - "}
            <NiceDate time={this.props.options.endTime - 1} />
          </div>)
          }
        </div>

        <div className="Content">
          <div className="OverallBar row valign-wrapper">
            <div className="col s6 left-align">
              Projects
            </div>
            <div className="col s6 right-align">
              HOURS
              <br />
              <NiceTime time={summary.total} />
              <br />
              <span className="aside">
                {summary.payable_total * 0.25} payable hours
              </span>
            </div>
          </div>

          {(projectComponents.length > 0) ?
            projectComponents :
            (<div>
              Nothing to display.
              <br /><br />
            </div>)
          }
        </div>

      </div>
    )

  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: 'timeblocks',
      where: [
        ['startTime', '>=', props.options.startTime],
        ['startTime', '<', props.options.endTime],
      ]
    },
    'users',
  ]),
  connect((state) => ({
    timeblocks: state.firestore.data.timeblocks,
    users: state.firestore.data.users,
  }))
)(ProjectSummary)
