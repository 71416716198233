import React, { Component } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'

import NiceTime from '../util/NiceTime'
import NiceDate from '../util/NiceDate'
import './Summary.css'


class Summary extends Component {
  state = {
    showNotes: false
  }
  getSummaryData = () => {
    let overallTotal = 0
    let users = {}

    if (this.props.timeblocks && this.props.users) {

      for (let userId in this.props.users) {
        users[userId] = {
          displayName: this.props.users[userId].displayName,
          total: 0,
          totalRated: 0,
          projects: {},
          userId: userId,
        }
      }

      for (let id in this.props.timeblocks) {
        let timeblock = this.props.timeblocks[id]
        //console.log(id, this.props.timeblocks[id])

        // Skip if timeblock has been deleted while viewing report
        if (
          !timeblock
          || timeblock.startTime < this.props.options.startTime
          || timeblock.startTime >= this.props.options.endTime
          ) continue

        // Increase overall total
        overallTotal += timeblock.length

        //Get used id of timeblock
        let userId = timeblock.userId

        // Increase user total
        users[userId].total += timeblock.length
        users[userId].totalRated += timeblock.length * timeblock.rate

        // Set project data if it doesn't exist
        if (!(timeblock.projectName in users[userId].projects)) {
          users[userId].projects[timeblock.projectName] = {
            total: 0,
            totalRated: 0,
            taskNos: [],
            taskNotes: {}
          }
        }
        let project = users[userId].projects[timeblock.projectName]

        // Increase user total for this project
        project.total += timeblock.length

        // Set task no data it doesn't exist
        let taskNo = timeblock.taskNo ? timeblock.taskName + ' - ' + timeblock.taskNo : timeblock.taskName
        if (!(taskNo in project.taskNos)) {
          project.taskNos[taskNo] = {
            total: 0,
            totalRated: 0,
          }
        }

        // Increase user total for this project
        project.taskNos[taskNo].total += timeblock.length

        if (timeblock.note) {
          if (!project.taskNotes[taskNo]) project.taskNotes[taskNo] = []
          project.taskNotes[taskNo].push(timeblock.note)
        }
      }
    }

    // Convert users from dictionary to array and sort by totalRated
    users = Object.values(users).sort((x,y) => {
      return y.totalRated - x.totalRated
    })

    return {
      'users': users,
      'overallTotal': overallTotal
    }
  }

  /*
   * Downloads a CSV holding a summary of the user data
   */
  downloadSummaryData = () => {
    console.log("Downloading summary CSV");

    let taskProjectDict = {};
    let userNameList = [];

    for (let id in this.props.timeblocks) {
      let timeblock = this.props.timeblocks[id];
      console.log(timeblock);

      // Skip timeblock if outside date range
      // Also if has been deleted while viewing report
      if (
        !timeblock
        || timeblock.startTime < this.props.options.startTime
        || timeblock.startTime >= this.props.options.endTime
        ) continue

      // The first two columns of the CSV
      // Primary key(s) kinda
      let taskProject = '';
      taskProject += timeblock.projectName;
      taskProject += ',' + timeblock.taskName || '';
      taskProject += ' - ' + (timeblock.taskNo || '');

      // Add taskProject if it doesn't exist
      if (!(taskProject in taskProjectDict)) {
        taskProjectDict[taskProject] = {
          taskProject: taskProject,
          users: {}
        }
      }

      // Add userName if it doesn't exist in taskProject
      let userName = this.props.users[timeblock.userId].displayName;
      if (!(userName in taskProjectDict[taskProject].users)) {
        taskProjectDict[taskProject].users[userName] = 0;
      }

      // Add rate for this user
      taskProjectDict[taskProject].users[userName] += timeblock.length * timeblock.rate;

      // Add username to list
      if (userNameList.indexOf(userName) === -1) userNameList.push(userName);
    }

    // TaskProject
    let taskProjectList = Object.keys(taskProjectDict);
    taskProjectList.sort();
    userNameList.sort();


    /* Build CSV */

    let csv = 'data:text/csv;charset=utf-8,';

    // Add header row
    csv += 'Project Name,Task/Project';
    for (let userName of userNameList) {
      csv += ',' + userName;
    }
    csv += '\n';

    // Add row for each taskProject
    for (let taskProject of taskProjectList) {
      csv += taskProject;

      for (let userName of userNameList) {
        csv += ',' + (taskProjectDict[taskProject].users[userName] || 0)/4;
      }

      csv += '\n';
    }

    // Sweden uses ISO date as their locale epic
    // https://stackoverflow.com/a/60368477/12688391
    let startDate = new Date(this.props.options.startTime).toLocaleDateString("sv");
    let endDate = new Date(this.props.options.endTime).toLocaleDateString("sv");

    var encodedUri = encodeURI(csv);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Envisage_Timesheets_${startDate}_${endDate}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  /*
   * I just want to begin by apologising for what you are about to read.
   * Now I will explain myself.
   *
   * Firestore is annoying and decides to download the users in chunks sometimes.
   * This means we might download all timeblocks but only half the users, so we error
   * when trying to extract user info for that particular timeblock. I've decided the
   * easiest fix atm is to just not render until all timeblock link up with a user.
   *
   * This means that if the database ever does miss a user for some reason, this page
   * will load forever. So yeah just keep that in mind for when that eventually happens.
   */
  downloadedAllUsers = () => {
    for (let timeblockId in this.props.timeblocks) {
      let timeblock = this.props.timeblocks[timeblockId];
      if (!(timeblock.userId in this.props.users)) {
        return false;
      }
    }
    return true;
  }


  render() {
    if (
        this.props.timeblocks === undefined
     || this.props.users === undefined
     || !this.downloadedAllUsers()
    ) {
      return <p>Loading Employee Summary...</p>
    }

    const taskStyle = this.state.showNotes ? { color: 'black' } : undefined
    let {users, overallTotal} = this.getSummaryData()

    // Add react components for user summaries
    let userSummaries = []
    for (let i in users) {
      let userSummary = users[i]
      let userId = userSummary.userId
      if (this.props.users[userId].isActive === false) continue

      let projects = [];
      for (let projectName in userSummary.projects) {
        let project = userSummary.projects[projectName]
        projects.push(
          <div className="ProjectSummary row valign-wrapper" key={projectName}>
            <div className="col s6 left-align">
              <Link
                className="individualProjectSummaryLink"
                to={`/reports/${userId}/${projectName}`}
              >
                {projectName}
              </Link>
            </div>

            <div className="col s6 right-align">
              <NiceTime time={project.total} />
            </div>

          </div>
        )

        for (let taskNo in project.taskNos) {
          projects.push(
            <div className="TaskNoSummary row" key={projectName + ' - ' + taskNo}>
              <div className="col s9 left-align">
                <i style={taskStyle}>{taskNo || 'No Task'}</i>
                {this.state.showNotes && project.taskNotes[taskNo] && <>
                  <br/>
                  {project.taskNotes[taskNo].map((note, index) => <span key={index}>{note}<br/></span>)}
                </>}
                {this.state.showNotes && !project.taskNotes[taskNo] && <span><br/>No notes</span>}
              </div>

              <div className="col s3 right-align">
                <NiceTime time={project.taskNos[taskNo].total} />
              </div>

            </div>
          )
        }
      }

      userSummaries.push((
        <div className="UserSummary row" key={userId}>
          <div className="UserSummaryTitle">
            <div className="row valign-wrapper" style={{marginBottom:"15px"}}>
              <div className="col s6 left-align">
                <Link
                  className="individualSummaryLink"
                  to={`/reports/${userId}`}
                >
                  {userSummary.displayName}
                </Link>
              </div>
              <div className="col s6 right-align">
                <NiceTime time={userSummary.total} /> worked
                <br/><i style={{
                  color: "red",
                  fontWeight: 50,
                }}>{userSummary.totalRated * 0.25} hours payable</i>
              </div>
            </div>
          </div>
          {projects}
        </div>
      ))
    }
    // console.log(userSummaries)


    return (
      <div className="Summary center-align card">

        <div className="UserSummaries">
          <div className="SummaryHeader row left-align">
            <span className="col s6 left-align">
              <div className="SummaryTitle">
                Employee Summary
              </div>
              <div className="SummaryDesc">
                <NiceDate time={this.props.options.startTime} />
                {" - "}
                <NiceDate time={this.props.options.endTime-1} />
              </div>
            </span>
            <span className="col s6 right-align">
              <div className="SummaryDownload">
                <button
                  className="btn"
                  onClick={this.downloadSummaryData}
                >
                    Download CSV
                </button>
              </div>
              <div>
                <br/>
                <div className="switch">
                  <label> 
                    Show Notes?   
                    <input type="checkbox" value={!!this.state.showNotes}  onClick={() => { 
                      this.setState({
                        showNotes: !this.state.showNotes
                      })
                    }}/>
                    <span className="lever"></span>   
                  </label>
                </div>     
              </div>
            </span>
          </div>
          <div className="SummaryContent">
            <div className="TitleSummary row valign-wrapper">
              <div className="col s6 left-align">
                Employee
            </div>
              <div className="col s6 right-align">
                HOURS
              <br />
                <NiceTime time={overallTotal} />
              </div>
            </div>

            {userSummaries}
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  firestoreConnect((props) => {
    return [{
        collection: 'timeblocks',
        where: [
          ['startTime', '>=', props.options.startTime],
          ['startTime', '<', props.options.endTime],
        ]
      },
      'users',
    ]}
),
  connect((state) => ({
    timeblocks: state.firestore.data.timeblocks,
    users: state.firestore.data.users,
  }))
)(Summary)
