import React, { Component } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import './ToggleEmployees.css'
import Popup from '../util/Popup'

import {
  enableUserFilterAction,
  disableUserFilterAction,
} from '../../store/actions/filters'


class ToggleEmployees extends Component {  
  toggleUserFilter = (evt) => {

    let userId = evt.target.value

    if (this.props.userFilters[userId]) {
        // console.log("dosableing")
        this.props.dispatch(disableUserFilterAction(userId))
    } else {
        // console.log("enabling")
        this.props.dispatch(enableUserFilterAction(userId))
    }
  }

  render() {
    return (
      <div className="ToggleEmployees">
        <br/>
        {/* Create a list of toggleable users */}
        <div className="EmployeeList row">
          {/*  Each user has a checkbox to toggle their visibility */}
          {Object.entries(this.props.users)
            .filter(([, user]) => user.isActive !== false)
            .map(([userId, user]) => <label className="col s12" key={userId}>
              <input name="rate" type="checkbox" onChange={this.toggleUserFilter} value={userId} checked={!!this.props.userFilters[userId]} />
              <span style={{ fontSize: "20px", marginTop: "10px" }}>{user.displayName}</span>
            </label>)}
        </div>

      </div>
    )
  }
}

export default Popup(compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    users: state.firestore.data.users,
    userFilters: state.filters.users,
  }))
)(ToggleEmployees), "Employees");