import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import './AddTimeblock.css'
import Popup from '../util/Popup'
import UpdateTimeblockFields from './UpdateTimeblockFields'


class AddTimeblock extends Component {
  constructor (props) {
    super(props)

    this.state = {
      startTime: this.props.startTime,
      length: 4,
      projectName: '',
      taskName: '',
      rate: 1,
      note: '',
      userId: this.props.auth.uid,
      overlaps: 0,
      x: 0,
    }

    // Props are passed a startTime which is zero by default
  }

  /** 
   * Stores new timeblock state recieved from UpdateTimeblockFields 
   */
  updateTimeblockState = (state) => {
    this.setState({
      ...state,
    })
  }


  addTimeblock = () => {
    // console.log("adding timeblock to firestore", this.state)
    this.props.firestore.collection('timeblocks').add(this.state)
    this.props.onClose()
  }

  render () {

    return (
      <div className="AddTimeblock">
        <br/><br/>

        <UpdateTimeblockFields 
          updateTimeblockState={this.updateTimeblockState} 
          timeblock={this.state}
        />

        <br/>

        <button 
          className="btn" 
          onClick={this.addTimeblock}
          disabled={this.state.projectName === ''}
        >
          Add Timeblock
        </button>

        <br/><br/>

      </div>
    )
  }
}

export default Popup(compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
  }))
)(AddTimeblock), "Add Timeblock");
