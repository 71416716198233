import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Route, Switch } from 'react-router-dom'
import Select from 'react-select';
import { Link } from 'react-router-dom'

import './ProjectManagement.css'
import NewProject from './NewProject'
import IndividualProject from './IndividualProject'


class ProjectManagement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popup: null
    }


  }

  setProjectStatus = (projectName, status) => {
    this.props.firestore.collection("projects").doc(projectName).update({
      status: status
    })
  }

  changeProjectStatus = (state) => {
    this.props.firestore.collection("projects").doc(state.projectName).update({
      status: state.label
    })
  }

  newProject = () => {
    let popup = (<NewProject onClose={this.closePopup} />)
    this.setState({
      ...this.state,
      popup: popup,
    })
  }

  closePopup = () => {
    this.setState({
      ...this.state,
      popup: null,
    })
  }

  render() {

    const projectRows = []

    if (this.props.projects) {
      for (let project of Object.values(this.props.projects)) {
        const statusOptions = {
          'Open': {label: 'Open', value: 'Open', color: 'green', projectName: project.name},
          'Closed': {label: 'Closed', value: 'Closed', color: 'red', projectName: project.name},
          'On Hold': {label: 'On Hold', value: 'On Hold', color: 'orange', projectName: project.name},
        }

        projectRows.push(
          <tr key={project.name}>
            <td style={{
              color: (statusOptions[project.status]) ? statusOptions[project.status].color : 'black'
            }}>{project.name}</td>
            <td className="right-align" style={{width: '10em'}}>
              <Select
                className="statusSelect smallSelect"
                isClearable={false}
                isSearchable={true}
                options={Object.values(statusOptions)}
                onChange={this.changeProjectStatus}
                defaultValue={statusOptions[project.status]}
              />
            </td>
            <td className="right-align" style={{width: '10em'}}>
              <Link to={`/project-management/${project.name}`}><button className="btn">Tasks</button></Link>
            </td>
          </tr>
        )
      }  
    }

    return (
      <div className="ProjectManagement">
        <Switch>
          <Route exact path="/project-management/" component={() => <>
            {this.state.popup}

            <div className="left-align">
              <button className="btn newProjectBtn" onClick={this.newProject}>New Project</button>
            </div>
    
            <table className="projectTable">
              <tbody>
                <tr>
                  <th>Project Name</th>
                  <th style={{textAlign: 'left'}}>Status</th>
                  <th style={{textAlign: 'right'}}></th>
                </tr>
                {projectRows}
              </tbody>
            </table>
          </>} />

          <Route path="/project-management/:projectName" component={(route) => {
            if (this.props.projects) {
              if (route.match.params.projectName in this.props.projects) {
                return <IndividualProject project={this.props.projects[route.match.params.projectName]} />
              } else {
                return <div style={{marginTop: '1em'}}>Project does not exist :(</div>
              }
            } else {
              return <div style={{marginTop: '1em'}}>Loading Project...</div>
            }
          }} />
        </Switch>

      </div>
    )
  }
}

export default compose(
  firestoreConnect((props) => {
    return [
      'projects',
    ]
  }),
  connect((state) => ({
    projects: state.firestore.data.projects,
  }))
)(ProjectManagement)
