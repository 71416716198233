import React, { Component } from 'react';
import Hammer from 'react-hammerjs'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import NiceTime from '../util/NiceTime'
import './Timeblock.css'


class Timeblock extends Component {

  constructor(props) {
    super(props)
    this.state = this.getCurrentGridState()

    this.moving = false
  }
  componentDidUpdate(prevProps) {
    // Check if length or startTime has changed and update state accordingly
    if (this.props.data.length !== prevProps.data.length || this.props.data.startTime !== prevProps.data.startTime) {
      this.setState(this.getCurrentGridState())
    }
  }

  setTimeBlockState = (x, y, length) => {   // Sets the local state of the timeBlock iff the new state is valid
    // Return if new state is NOT valid
    if (length < 1) return;
    if (x < 0 || x >= 7) return
    if (y < 0 || y + length > 24 * 4) return;

    this.setState({
      ...this.state,
      x: x,
      y: y,
      length: length,
    });
  }


  getCurrentGridState() {
    // Calculates the grid state (x/y/length) of the time block from its startTime/length
    // Temporary way to calculate grid pos until the best way is determined by the programs needs :)
    let dayLength = 1000 * 60 * 15 * 4 * 24
    let timeOffset = this.props.data.startTime - this.props.startTime

    return {
      length: this.props.data.length,
      x: Math.floor(timeOffset / dayLength),
      y: Math.floor((timeOffset % dayLength) / (1000 * 60 * 15)),
      panYOffset: 0,
    }
  }

  handleDoubleTap = (evt) => {
    this.props.editAction(this.props.data)
  }
  handlePanReposition = (evt) => {
    let xPan = evt.center.x - evt.target.getBoundingClientRect().x// xPan and yPan should be how far the pointer has moved since it started this pan.
    let yPan = evt.center.y - evt.target.getBoundingClientRect().y - this.state.panYOffset
    // Move to right
    if (xPan > evt.target.getBoundingClientRect().width) {
      this.setTimeBlockState(
        this.state.x + 1,
        this.state.y,
        this.state.length,
      )
      xPan = evt.center.x - evt.target.getBoundingClientRect().x
      if (!this.moving) {
        this.props.resetDimensions(this.props.id)
        this.moving = true
      }
    }

    // Move to left
    if (xPan < 0) {
      this.setTimeBlockState(
        this.state.x - 1,
        this.state.y,
        this.state.length,
      )
      xPan = evt.center.x - evt.target.getBoundingClientRect().x
      if (!this.moving) {
        this.props.resetDimensions(this.props.id)
        this.moving = true
      }    }

    // Move down
    if (yPan > 12.5) {
      this.setTimeBlockState(
        this.state.x,
        this.state.y + 1,
        this.state.length,
      )
      yPan = evt.center.y - evt.target.getBoundingClientRect().y - this.state.panYOffset
      if (!this.moving) {
        this.props.resetDimensions(this.props.id)
        this.moving = true
      }    }

    // Move up
    if (yPan < 0) {
      this.setTimeBlockState(
        this.state.x,
        this.state.y - 1,
        this.state.length,
      )
      yPan = evt.center.y - evt.target.getBoundingClientRect().y - this.state.panYOffset
      if (!this.moving) {
        this.props.resetDimensions(this.props.id)
        this.moving = true
      }    }
  }
  handlePanResizeTop = (evt) => {
    const yPan = evt.center.y - evt.target.getBoundingClientRect().y
    if (yPan > 12.5) {
      this.setTimeBlockState(
        this.state.x,
        this.state.y + 1,
        this.state.length - 1,
      )
    }

    if (yPan < 0) {
      this.setTimeBlockState(
        this.state.x,
        this.state.y - 1,
        this.state.length + 1,
      )
    }

  }
  handlePanResizeBottom = (evt) => {
    const yPan = evt.center.y - evt.target.getBoundingClientRect().y

    if (yPan > 12.5) {
      this.setTimeBlockState(
        this.state.x,
        this.state.y,
        this.state.length + 1,
      )
    }

    if (yPan < -12.5) {
      this.setTimeBlockState(
        this.state.x,
        this.state.y,
        this.state.length - 1,
      )
    }
  }
  handlePanStart = (evt) => {
    // Store the offset of the mouse from the top of the timeblock and maintain this offset during repositioning
    this.setState({
      ...this.state,
      panYOffset: evt.center.y - evt.target.getBoundingClientRect().y - evt.deltaY,
    })
  }
  handlePanEnd = (evt) => {
    /// Update firestore of timeBlock

    // Calculate startTime in epoch based on grid location
    let startTime = this.props.startTime + 1000*60*15*4*24*this.state.x + 1000*60*15*this.state.y

    // Set new values in firestore
    this.props.firestore.collection("timeblocks").doc(this.props.data.id).update({
      startTime: startTime,
      length: this.state.length,
    });
    // Stop resetting the dimensions of the currently moving block
    this.props.resetDimensions(null)
    this.moving = false
    
  }

  render () {

    let options = {
      recognisers: {
        pan: {
          threshold: 1
        }
      }
    }

    let style = {
      gridColumn: (this.state.x + 2) + "/" + (this.state.x + 3),
      gridRow: (this.state.y + 1) + "/" + (this.state.y + 1 + this.state.length),
      // border: (this.props.data.rate == 1) ? "1px solid #0079db" : "5px solid orange",
      boxShadow: (this.props.data.rate === 1) ? "" : ((this.props.data.rate === 1.5) ? ("0px 0px 5px 3px violet") : "0px 0px 10px 3px orange"),
      
      width: (1 / (1 + this.props.data.overlaps) * 100).toString()+'%',
      marginLeft: ((this.props.data.x * 1/(1+this.props.data.overlaps) * 100)).toString()+'%'
    }

    return (
      <div className="Timeblock" style={style}>

        <Hammer
          className="InteractableContent"
          onDoubleTap={this.handleDoubleTap}
          onPan={this.handlePanReposition}
          onPanStart={this.handlePanStart}
          onPanEnd={this.handlePanEnd}
          direction="DIRECTION_ALL"
          options={options}>
          <span></span>
        </Hammer>

        <Hammer
          className="ResizeTop"
          onPan={this.handlePanResizeTop}
          onPanEnd={this.handlePanEnd}
          direction="DIRECTION_VERTICAL">
          <span></span>
        </Hammer>

        <Hammer
          className="ResizeBottom"
          onPan={this.handlePanResizeBottom}
          onPanEnd={this.handlePanEnd}
          direction="DIRECTION_VERTICAL">
          <span></span>
        </Hammer>

        {/*The content contained within this time block*/}
        <div className="TextContent left-align">
          <div className="ProjectName truncate">
            {this.props.data.projectName}
          </div>
          <div className="Desc">
            { 
              (this.props.data.taskName || this.props.data.taskNo) 
              ? (<span>{
                (this.props.data.taskNo ? '' + this.props.data.taskNo + ' - ' : '')
                + this.props.data.taskName
              }<br/></span>)
              : null
            }
            <NiceTime time={this.state.length} />
            <br />
            {this.props.data.userName}
            <br />
            {
              (this.props.data.rate !== 1)
                ? <span>{this.props.data.rate}x<br /></span>
                : null
            }
            <div className="timeblockNote">
              {this.props.data.note}
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default compose(
  firestoreConnect(() => [
    'filters',
  ]),
  connect(state => ({
    startTime: state.filters.dates.startTime,
  }))
)(Timeblock);
