import React, { Component } from 'react'
//------------------This file was made by NiceDate gang----------------
//-----------------NiceDate  >   NiceTime----------------------------
class NiceDate extends Component {
  render() {

    let timestamp = this.props.time
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = date.toLocaleString('en-us', { month: 'long' });
    let day = date.getDate();
    // Display date time in MM-dd-yyyy format
    let converted_date = day+' '+month+' '+year;


    return (<span>{converted_date}</span>)

  }
}
export default NiceDate
